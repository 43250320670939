// api/index.js
import axios from 'axios';

//import moment from 'moment'

import parser from '@/helper/base_parser.js'


const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URI,
  //baseURL: 'http://127.0.0.1:8000/api/v1/',
  // baseURL: 'https://211.188.69.79:8000/api/v1/',
  // baseURL: 'https://rare.ccrdp.org:8000/api/v1/',
  withCredentials:true,
});


const baseURL =process.env.VUE_APP_BACKEND_URI

export default {
  
  async getHospitalDisplayList(){
    return instance.get("/hospitalstat/display/")
  },
  async getStatData(){
    //console.log ('getStatData')
    return instance.get("/patient/statview/")
  },
  async getStatManagerData(){
    return instance.get("/patient/statviewmanager/")
  },
  // About user
  async getBaseUrl(){
    console.log(baseURL)
    return baseURL
  },
  // About user
  async signup(userInfo){
    return instance.post('/user/signup/',userInfo)
  },
  async getHospitalList(){
    return instance.get("/hospital/")
  },
  async getAreaList(){
    return instance.get("/area/")
  },
  async getOmimList(){
    const config = parser.getConfigWithToken();
    return instance.get("/disease_codes/omim_list/",config)
  },
  async getHpoList(){
    const config = parser.getConfigWithToken();
    return instance.get("/disease_codes/hpo_list/",config)
  },
  async login (loginObj) {
    try{

      const res = await instance.post("/user/api-token-auth/",loginObj)
      //alert ('instance.post res:'+JSON.stringify(res))

      // 로컬 스토리지에 토큰을 저장함
      if (res.data.token != '' && res.data.token != null){
        //alert ('res.data.token:'+res.data.token)
        localStorage.setItem('jwt',res.data.token)

      }

      // user store에 login 정보 저장

      return res;

    } catch(err){
      alert ("Email과 비밀번호를 확인하세요")// , err:"+JSON.stringify(err))
      console.error(err);
      return err;
    }
  },
  async logout(){
    // alert ('api.logout is called')
    localStorage.removeItem('jwt');
  },
  //api.send_verify_code(this.user.phone_number,this.verify.code)

  async send_verify_code(sms_number,code){
    try{
      //const config = parser.getConfigWithToken();



      let requestJSON={
        action:'verify_code' ,
        data:{'sms_number':sms_number, 'code':code}

      }
      const res_patient = await instance.post('/user/verify_sms_code/',requestJSON)
      
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  async send_verify_sms_code(sms_number){
    try{
      //const config = parser.getConfigWithToken();



      let requestJSON={
        action:'send_sms' ,
        sms_numbers:[sms_number]
      }
      //alert ("instance:"+JSON.stringify(instance))
      const res_patient = await instance.post('/user/send_verify_sms_code/',requestJSON)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  // api.sendEmail(this.email, "회원 가입을 위한 이메일을 전송합니다." ," 본문입니다.")
  async sendEmail(eamilto,subject,body){
    try{
      //const config = parser.getConfigWithToken();


      let requestJSON={
        service:'email',
        emailto:eamilto,
        subject:subject,
        body:body
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.post('/helper/email/',requestJSON)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  async getUserInfo(loginObj){
    try{
      const config = parser.getConfigWithToken();
      //alert ('services:getUserInfo with loginObj and config:'+JSON.stringify(loginObj)+'\n'+JSON.stringify(config))

      //alert ('getUserInfo(uid):'+JSON.stringify(loginObj))
      const res = await instance.post("/user/user/",loginObj,config)
      //console.log("services:getUserInfo => res:"+JSON.stringify(res));
      return res;
    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }

  },
  async delete_family_obj(user_email,family_obj_id){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      //let req_family_obj= await this.constructRequestFamilyObjData(family_obj)

      // let requestJSON={
      //   email:user_email,
      //   data:''
      // }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.delete('/patient/delete/familyobj/'+family_obj_id+'/',config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  async delete_patient_obj(patient_id){
    try{
      const config = parser.getConfigWithToken();
      const res_patient = await instance.delete('/patient/delete/'+patient_id+'/',config)
      return res_patient['data']
    } catch(err) {
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  // family object 를 생성 함
  async add_family_db(user_email, cr_family_obj,req_patient_id){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      let family_obj= await this.constructRequestFamilyObjData(cr_family_obj)
      family_obj['patient_id'] = req_patient_id

      let requestJSON={
       //email:user_email,
        family_obj
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.post('/patient/register/familyobj/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },


  // ecrf object 를 업데이트 함
  async patch_sms_obj(user_email, sms_obj, stakeholder_obj){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))

      let stakeholder_data = await this.constructRequestStakeholderData(stakeholder_obj)

      // additional_smss
      let sms_obj_data=await this.constructRequestSMSObjData(sms_obj)
      sms_obj_data['sms_number'] = stakeholder_data['text_stakeholder_sms_number']
      sms_obj_data['email'] = stakeholder_data['text_stakeholder_email']
      let requestJSON={
        email:user_email,
        data:sms_obj_data
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.patch('/patient/register/sms_obj/'+sms_obj.id+'/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }

  },

  // ecrf object 를 업데이트 함
  async patch_ecrf_obj(user_email, ecrf_obj){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      let req_ecrf_obj = await this.constructRequestECRFData(ecrf_obj)

      let requestJSON={
        email:user_email,
        data:req_ecrf_obj
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.patch('/patient/register/ecrf_obj/'+ecrf_obj.id+'/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }

  },
  // family object 를 업데이트 함
  async patch_family_obj(user_email, family_obj){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      let req_family_obj= await this.constructRequestFamilyObjData(family_obj)


      let requestJSON={
        email:user_email,
        data:req_family_obj
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.patch('/patient/register/familyobj/'+family_obj.id+'/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  async patch_pb_obj(user_email, pb_obj){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      let req_pb_obj = await this.constructRequestPBObjData(user_email,pb_obj)
      
      let requestJSON={
        email:user_email,
        data:req_pb_obj
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.patch('/patient/register/pbobj/'+pb_obj.id+'/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }

  },
  async patch_pb_obj_reqstatus(user_email, id, reqstatus, managercomment){
    try{
      var today = this.tiemstamp_today()
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))

      let requestJSON={
        email:user_email,
        id:id,
        data:{ reqstatus:reqstatus,requested: today,manager_comment:managercomment}
      }
      //alert ('requestJson:'+JSON.stringify(requestJSON))
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.patch('/patient/patient/status/'+id+'/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  // upload 된 검진 리포트를 다시 롤백한다.
  async rollBackReport(user_email, id, reqstatus, _fidx){
    try{
     
     
      
      // alert ('today:'+JSON.stringify(today))
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))

      let requestJSON={
        email:user_email,
        id:id,
        operation:'ROLLBACK_REPORT',
        data:{ reqstatus:reqstatus,  redeliver_fid : _fidx }
      }
      

      const res_patient = await instance.patch('/patient/patient/status/'+id+'/',requestJSON,config)
      const res_patient_result = res_patient['data']

      return res_patient_result
    }
    catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  

  async deliverReport1(user_email, id, reqstatus, _fidx, _report_type){
    try{
     
      var today = this.timestamp_to_str('')
      
      // alert ('today:'+JSON.stringify(today))
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))

      let requestJSON={
        email:user_email,
        id:id,
        operation:'DELIVER_REPORT',
        data:{ reqstatus:reqstatus,  redeliver_date: today, redeliver_fid : _fidx , report_type:_report_type}
      }
      

      const res_patient = await instance.patch('/patient/patient/status/'+id+'/',requestJSON,config)
      const res_patient_result = res_patient['data']

      return res_patient_result
    }
    catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  // 검사 결과를 의뢰 기관에 전달한다.
  // api.deliverReport(userEmail, p_id, "검사완료" , _fields)
  async deliverReport(user_email, _status, _fields){
    try{
     
      var today = this.timestamp_to_str('')
      
      // alert ('today:'+JSON.stringify(today))
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))

      let requestFieldsJSON={
        email:user_email,
        id:_fields.patient_id,
        operation:'DELIVER_REPORT',
        data: {
          patient_id: _fields.patient_id, 
          patientfiles_id:_fields.patientfiles_id, 
          report_date: today, 
          report_kind: _fields.report_kind, 
          report_result:_fields.report_result,
          report_type : _fields.report_type,
          report_value : _fields.report_value,
          
          
          // added by dsryu seq_type  20241103
          seq_type : _fields.seq_type,
          
          
          revoke: _fields.revoke,
          // updated_at: _fields.updated_at,


          reqstatus: _status
        },
        //data:{ reqstatus:reqstatus,  redeliver_date: today, redeliver_fid : _fidx , report_type:_report_type}
      }
      
      
      const res_reported = await instance.post('/patient/patient/report/',requestFieldsJSON,config)
      const res_reported_result = res_reported['data']


      //patient_patient table에 report_type 변환, status와 redeliver_fid, redeliver_date 등을 변환한다.
      if (res_reported_result['result'] == 'success'){

        let report_type = "__FILE__"

        if (_fields.report_type == 'FILE'){
          report_type = '__FILE__'
        }
        else if (_fields.report_type == 'WOFILE'){
          report_type = "__WOFILE__"
        }
        else if (_fields.report_type == 'EMR'){
          report_type = "__EMR__"
        }

        let file_id = ''
        if (_fields.report_type == 'FILE'){
          file_id = _fields.patientfiles_id
        }


        let requestJSON={
          email:user_email,
          id:_fields.patient_id,
          operation:'DELIVER_REPORT',

          // redeliver_date
          data:{ reqstatus:_status, redeliver_date: today,  redeliver_fid :file_id, report_type:report_type}
        }
        

        const res_patient = await instance.patch('/patient/patient/status/'+_fields.patient_id+'/',requestJSON,config)
        const res_patient_result = res_patient['data']
        if (res_patient_result['result'] == 'success'){
          return res_patient_result
        }
        else{
          alert ("Some Error in status change in deliverReport()" +res_patient_result['message'])

          return res_reported_result
        }

      }
      else{
        alert ("Some Error in deliverReport()" +res_reported_result['message'])

        return res_reported_result
      }
 
        
    }
    catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  // 환자 검체 번호 변경
  async patch_sample_number(patient_id, sample_number){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      // let req_pb_obj = await this.constructRequestPBObjData(user_email,pb_obj)

      let requestJSON={
        id:patient_id,
        data:{ sample_number: sample_number}
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.patch('/patient/patient/sample/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }

  },
  // 3Bil 분석 시작 API 실행
  // await api.noti_tbil_analysis(userEmail, patient_id, operation)
  async noti_tbil_analysis(_user_email, _patient_id,_operation){
    try{
      const config = parser.getConfigWithToken();

      const user_email = _user_email

      const operation = _operation

      const patient_id = _patient_id
      
      console.log('api.noti_tbil_analysis with user_email:'+JSON  .stringify(patient_id))
      // let req_pb_obj = await this.constructRequestPBObjData(user_email,pb_obj)

      let requestJSON={
        patient_id:patient_id,
        operation:operation,
        email: user_email,
        data:{}
      }




      
      const res_patient = await instance.post('/patient/api/noti_tbil_analysis/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))

      // console.log(JSON.stringify(res_patient))
      // alert ("res_patient:"+JSON.stringify(res_patient))
      return res_patient['data']

    } catch(err){
      //alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      //console.error(err);
      return err;
    }
  },
  // 가족 검체 번호 변경
  async patch_family_sample_number(family_id, family_sample_number){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      // let req_pb_obj = await this.constructRequestPBObjData(user_email,pb_obj)

      let requestJSON={
        id:family_id,
        data:{ family_sample_number: family_sample_number}
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient = await instance.patch('/patient/family/sample/',requestJSON,config)
      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },





  //user_email, _id 에 해당하는 patient 객체를 반환한다
  async getRequestedPatient(user_email,id){
    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))

      let requestJSON={
        email:user_email,
        id:id
      }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)

      const res_patient = await instance.post("/patient/patient/",requestJSON,config)

      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']
      //return rearranged_response['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  //user_email 에 해당하는 patient list 를 반환한다
  async getRequestedPatients(user_email, current_page, page_size, page_type, search_data){
    try{
      const config = parser.getConfigWithToken();
      // console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      let requestJSON={
        email:user_email,
        current_page:current_page,
        page_size:page_size,
        page_type:page_type, // "SCREEN" or "REQUEST"
        search_data:search_data
      }
      // console.log("requestJSON : " + requestJSON)
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)
      const res_patient_list = await instance.post("/patient/list/",requestJSON,config)
      // console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient_list['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  // new patient upload file들 데이터를 정리한다. , _prefix, _type, _family_array_idx
  async filearrayToFormData(_formData, _req_id, _files){

    // file_metadata format
    //---------------------------------
    // const fileinfo = upload_files[i]
    //   const prefix = fileinfo['prefix']
    //   const filetype = fileinfo['filetype']
    //   const family_array_index = fileinfo['array_idx']
    //   const db_id = fileinfo.id

    const req_id = _req_id
    const upload_files = _files

    for (let i=0;i<upload_files.length;i++){
      // filesbpprefix_arrayindex(0)_pbarrayindex(category)_basispk
      // 환자 정보의 파일 업로드를 위한 form data key  : files_pb_prefix_[구분 : basepatient 는 하나 뿐인지라 0으로 구분]_[업로드할 파일 타입: filetype_pb[j]]_[환자 아이디: new_patient_id]

      // [prefix]_[family array index]_[file category]_[patient or family id]_[file pk]
      // [prefix]_[-]_[file category]_[patient id]


      const fileinfo = upload_files[i]
      const prefix = fileinfo['prefix']
      const filetype = fileinfo['filetype']
      //const family_array_index = fileinfo['array_idx']
      const db_id = fileinfo.id
      let file_metadata = prefix+"_"+req_id+"_"+filetype



      // Add File pk to file_metadata variable
      if (db_id== undefined || db_id ==''){
        file_metadata= file_metadata+"_-" // No db id '-'로 대체
      }else{
        file_metadata= file_metadata+"_" +db_id// Add db id
      }


      //file_metadata= file_metadata+"_"+family_array_index // 가족 배열 인덱스 할당

      file_metadata= file_metadata +"_"+i // formdata  변수 구분자

      // add memory file information
      if (fileinfo ==null){
        _formData.append(file_metadata, 'undefined')
      }
      else{
        _formData.append(file_metadata, fileinfo)
      }
    }
    return
  },
  async deleteFileObj(_prefix,_id){

    try{
      const config = parser.getConfigWithToken();
      //console.log('api. getRequestedPatients with user_email:'+JSON.stringify(user_email))
      //let req_family_obj= await this.constructRequestFamilyObjData(family_obj)

      // let requestJSON={
      //   email:user_email,
      //   data:''
      // }
      //const res_patient = await instance.post("/patient/request/",requestBasisPatientData,config)

      let res_patient ={}
      if (_prefix =="filesfamily"){
        res_patient = await instance.delete('/patient/file/delete/familyfileobj/'+_id+'/',config)
      }
      else{
        res_patient = await instance.delete('/patient/file/delete/patientfileobj/'+_id+'/',config)
      }

      //console.log('api. getRequestedPatients res_patient:'+JSON.stringify(res_patient_list))
      return res_patient['data']

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  async uploadFileObj(_fileList){
    let file_upload_result='';
    try{
      // 시작: 파일 업로드를 위한 준비
      //-------------------------
    //   let file_info = {
    //     prefix:this.$fileconstant.pb_prefix,
    //     category:this.$fileconstant.pb_types[_file_idx],
    //     required:'REQUIRED',
    //     file: this.pb_obj.uploadfiles[_file_idx]
    // }

      const config = parser.getConfigWithToken();

      // 1. make formData
      //---------------------
      let formData = new FormData()
    //   let file_info = {
    //     prefix:this.$fileconstant.pb_prefix,
    //     filter_id:this.pb_obj.id, // patient_id or family id
    //     category:this.$fileconstant.pb_types[_file_idx],
    //     required:'REQUIRED',
    //     array_idx:i,
    //     file: this.pb_obj.uploadfiles[_file_idx]
    // }
      for (let i=0;i<_fileList.length;i++){
        const fileobj = _fileList[i]
        // let file_metadata = fileobj.prefix + "_"+fileobj.filter_id + "_"+ fileobj.category +"_"+fileobj.array_idx+"_"+fileobj.required
        let file_metadata = fileobj.prefix + "_"+fileobj.req_id + "_"+ fileobj.filetype +"_"+fileobj.db_id +"_"+i

        formData.append(file_metadata,fileobj)
      }
      // 시작: 파일 업로드
      //------------------------

      const res_file_upload = await instance.post("/patient/file/upload/",formData,config)
      file_upload_result = res_file_upload['data']
      // console.log("console.log(file_upload_result)")
      // console.log(file_upload_result)
      if (file_upload_result['result'] == "fail"){
        // rollback 코드 필요
        console.log("Error inservices:registerPatient => upload_file_obj: 'fail' and error message :" +JSON.stringify(res_file_upload));
        throw new Error('Error in services:sendRequestPatient /patient/file/upload/ with '+JSON.stringify(res_file_upload));
        // request pk delete action 필요
      }
      else{
        console.log("Success services:sendRequestPatient/patient/file/upload/ with success and transfered file list : "+JSON.stringify(file_upload_result['data']));
        alert ('추가한 파일이 성공적으로 업로드 완료되었습니다.')
        return file_upload_result
      }
      // 끝: 파일 업로드
      //---------------------------

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(file_upload_result));
      console.error(err);
      return file_upload_result;
    }

  },
  async addNewFamilyUploadfiles(_formData, obj, _fileconstant){

    _formData.append(_fileconstant.fi_prefix+'_length',obj.length) // family 개수를 backend로 보내 유효한 family 개수에 따라 파일 처리하게 한다

    for (let i = 0 ; i<obj.length;i++){
      const family_info = obj[i];


      for (let j = 0; j<family_info.uploadfiles.length;j++){
        // [prefix]_[family array index]_[file category]_[patient or family id]_[file pk]
        // [prefix]_[family array index:i]_[file category]_[family id]
        let file_metadata = _fileconstant.fi_prefix+"_"+family_info.id+"_"+_fileconstant.fi_types[''+j]

        // Add File pk to file_metadata variable
        if (family_info.uploadfiles_meta[j].id == undefined || family_info.uploadfiles_meta[j].id ==''){
          file_metadata= file_metadata+"_-" // No dbid => '-'로 대체
        }else{
          file_metadata= file_metadata+"_" + family_info.uploadfiles_meta[j].id// Add db id
        }

        if (family_info.uploadfiles[j] == null){
          _formData.append(file_metadata,'undefined')
        }
        else{
          _formData.append(file_metadata,family_info.uploadfiles[j])
        }

      }
    }
    return

  },
  // Backend에 전달할 pb_obj 데이터 셋을 만든다.
  async constructRequestPBObjData(user_email, pb_obj){

    var today = this.tiemstamp_today()

    // 다시 omim_codes 와 hpo_codes를 배열로 변환한다.
    let omim_codes = parser.make_code_array(pb_obj.pb_diseasename_omim,"|");
    let hpo_codes = parser.make_hpo_code_array (pb_obj.pb_diseasename_hpo,pb_obj.pb_diseasename_hpo_textnumber,"|");

    // console.log(JSON.stringify(pb_obj))

    let requestBasisPatientData={
      id:'',
      email:user_email,
      name: pb_obj.text_pb_name,
      updated_at:today,
      number: pb_obj.text_pb_number,
      sex: pb_obj.sel_pb_sex,
      pb_diseasename_text: pb_obj.pb_diseasename_text,
      birthday:pb_obj.text_pb_birthday,
      omim_codes:omim_codes,
      hpo_codes:hpo_codes,
      age:pb_obj.age,
      age_type:pb_obj.age_type,


      etcopinion: pb_obj.text_pb_etcopinion, // 기타 중요 소견


      bs_date_at:pb_obj.text_pb_blooddate,
      reqreferal_date:pb_obj.text_pb_reqreferal_date,
      sample_number:pb_obj.text_sample_number,
      key_clinical_info:pb_obj.text_key_clinical_info, // 주요 임상 정보
      // api_auth_key_1, // 3billion Auth Key_1
      // api_auth_key_2, // 3billion Auth Key_2
      area_code:pb_obj.sel_area_code, // 지역 코드
      // stakeholder_name:pb_obj.stake_holder.text_pb_stakeholder_name,
      // stakeholder_phonenumber:pb_obj.stake_holder.text_pb_stakeholder_phonenumber,
      // clinician_name:pb_obj.stake_holder.text_pb_doctor_name,
      // clinician_phonenumber:pb_obj.stake_holder.text_pb_doctor_phonenumber,
      // clinician_major:pb_obj.stake_holder.text_pb_doctor_major,

      // 가족 정보를 families 정보로 access  한다
      families: [],
      ecrf_obj: [] ,  // 임상 정보
      additional_contractor:[]
    }



    // requestPatient 데이터 Json 만들기 끝
    //--------------------------------

    return requestBasisPatientData

  },
  async constructRequestFamilyObjData(family_info){
    var today = this.tiemstamp_today()
    let requestFamilyObjData={
      id: '',
      // array_idx:family_info.array_idx,
      name: family_info.text_family_name,
      updated_at:today,
      relation:family_info.sel_family_relation,
      // generation:family_info.sel_family_generation,
      sex: family_info.sel_family_sex,
      birthday: family_info.text_family_birthday,
      sympton: family_info.sel_family_sympton,
      etc: family_info.text_family_etc,
      family_sample_number: family_info.text_family_sample_number

    }
    if (family_info['id'] != undefined){
      requestFamilyObjData.id = family_info['id']
    }
    //alert ('requestFamilyObjData:'+JSON.stringify(requestFamilyObjData))
    return requestFamilyObjData;
  },
  //  Backend에 전달할 family 데이터 셋을 만든다.
  async constructRequestFamilyData(family){
    var requestFamilyData=[];

    for (let i=0;i<family.length;i++){
      const family_info = family[i]
      // console.log ('family info In constructRequestFamilyData:'+JSON.stringify(family_info));
      const constructed_family_info = await this.constructRequestFamilyObjData(family_info)
      requestFamilyData.push(constructed_family_info)
      // console.log ('In constructRequestFamilyData:'+JSON.stringify(requestFamilyData[i]));
    }

    // console.log ('In after constructRequestFamilyData:'+JSON.stringify(requestFamilyData));
    return requestFamilyData;
  },
  // Backend에 전달할 요청 ECRF 데이터 셋을 만든다
  async constructRequestECRFData(ecrf_obj){
    let ecrf_data ={};

    ecrf_data.id = ''

    if (ecrf_obj.id != undefined){
      ecrf_data.id = ecrf_obj.id
    }

    ecrf_data.imgscr = ecrf_obj.imgscr
    ecrf_data.org = ecrf_obj.org
    ecrf_data.blood = ecrf_obj.blood
    ecrf_data.genescreen = ecrf_obj.genescreen
    return ecrf_data;
  },

  // Backend에 전달할 stakeholder 데이터 셋을 만든다
  async constructRequestStakeholderData(stakeholder){
    // let stakeholder_array=[]


    // email concatenation

    let return_value = {}
    if (stakeholder.length !=0){
      let email_str = stakeholder[0].text_stakeholder_email
      let sms_str = stakeholder[0].text_stakeholder_sms_number

      for (let i=1;i<stakeholder.length;i++){
        const stakeholder_content = stakeholder[i]

        email_str = email_str+  "|" + stakeholder_content['text_stakeholder_email']
        sms_str =  sms_str+  "|" + stakeholder_content['text_stakeholder_sms_number']
      }
      return_value['text_stakeholder_sms_number'] = sms_str
      return_value['text_stakeholder_email'] = email_str
    }




    return return_value;
  },

  // Backend에 전달할 sms_obj 데이터 셋을 만든다.
  async constructRequestSMSObjData(sms_obj){
    let sms_obj_data ={};

    sms_obj_data.id = ''

    if (sms_obj_data.id != undefined){
      sms_obj_data.id = sms_obj.id
    }

    sms_obj_data.stakeholder_name = sms_obj.text_sms_stakeholder_name
    sms_obj_data.stakeholder_phonenumber = sms_obj.text_sms_stakeholder_phonenumber
    sms_obj_data.stakeholder_email = sms_obj.text_sms_stakeholder_email
    sms_obj_data.request_doc_name = sms_obj.text_sms_request_doc_name
    sms_obj_data.request_doc_phonenumber = sms_obj.text_sms_request_doc_phonenumber
    sms_obj_data.request_doc_email = sms_obj.text_sms_request_doc_email
    // sms_obj_data.doctor_name = sms_obj.text_sms_doctor_name
    // sms_obj_data.doctor_phonenumber = sms_obj.text_sms_doctor_phonenumber
    // sms_obj_data.doctor_major = sms_obj.text_sms_doctor_major

    return sms_obj_data;
  },

  // Backend에 전달할 요청 sms_obj 데이터 셋을 만든다

  // Backend에 전달할 요청 환자 데이터 셋을 만든다
  async constructRequestPatientData(user_email, pb_obj, family, ecrf_obj, contract_sms, stakeholder_obj){

    let requestBasisPatientData = await this.constructRequestPBObjData(user_email,pb_obj)

    // ecrf 정보를 필터링한다.
    let ecrf_data = await this.constructRequestECRFData(ecrf_obj)
    // 추가로 입력한 sms 정보들을 한줄 문자열(구분자 '|')로 이어 붙인다.


    let stakeholder_data = await this.constructRequestStakeholderData(stakeholder_obj)

    // additional_smss
    let sms_obj_data=await this.constructRequestSMSObjData(contract_sms)

    sms_obj_data['sms_number'] = stakeholder_data['text_stakeholder_sms_number']
    sms_obj_data['email'] = stakeholder_data['text_stakeholder_email']


    // 진단 요청된 환자의 가족 데이터를 Json 형태로 변경한다.
    let requestFamilyData = await this.constructRequestFamilyData(family)



   // let stakeholderData = await this.constructRequestStakeholderData(stakeholder_obj)


    // alert ('after constructRequestFamilyData:'+JSON.stringify(requestFamilyData));

    // 가족 정보를 families 정보로 access  한다
    requestBasisPatientData['families']=requestFamilyData
    requestBasisPatientData['ecrf_obj']=[ecrf_data]  // 임상 정보
    requestBasisPatientData['additional_contractor']=[sms_obj_data] // sms obj 정보


    // requestPatient 데이터 Json 만들기 끝
    //--------------------------------

    // alert ('bef requestBasisPatientData:'+JSON.stringify(requestBasisPatientData));

    return requestBasisPatientData
  },
  async patch_families(user_email, patient_id, family,_fileconstant ){
    const config = parser.getConfigWithToken();
    let requestFamilyData = await this.constructRequestFamilyData(family)
    //add patient_id to requestFamilyData


    for (let i=0;i<requestFamilyData.length;i++){
      requestFamilyData[i]['patient_id'] = patient_id
    }

    let totalRequestFAmilyData ={
      patient_id:patient_id,
      requestFamilyData:requestFamilyData
    }
    console.log('api.patch_families:'+requestFamilyData +' with patient_id:'+patient_id+', eamil:'+user_email+', config:'+JSON.stringify(config))

    try{

      const res_families=  await instance.post("/patient/register/families/",totalRequestFAmilyData,config)
      console.log('res_families:'+JSON.stringify(res_families));
      // 시작: 파일 업로드
      //---------
      const response_data = res_families.data

      let formData = new FormData()
      if (response_data.result == 'success'){
        const new_families = response_data['data']
        for (let i=0;i<new_families.length;i++){
          const family_obj = new_families[i]['family_index']
          console.log('family_obj:'+JSON.stringify(family_obj));

          const new_family_dbid=family_obj.id
          const fa_obj = family[i];
          let fa_reqfiles = fa_obj.reqfiles

          for (let j=0;j<fa_reqfiles.length;j++){
            fa_reqfiles[j].id = '-'

            fa_reqfiles[j].prefix = _fileconstant.fi_prefix
            // 파일 타입 설정
            fa_reqfiles[j].filetype = 'REQ'
            // 패밀리 array index 설정
            //fa_reqfiles[j].array_idx = i
          }
          await this.filearrayToFormData(formData,new_family_dbid, fa_reqfiles)
        }
        // 시작: 파일 업로드
        //------------------------
        const res_file_upload = await instance.post("/patient/file/upload/",formData,config)
        const file_upload_result = res_file_upload['data']['result']
        const file_upload_result_data = res_file_upload['data']['data']

        if (file_upload_result == "fail"){
          // rollback 코드 필요
          console.log("Error inservices:registerPatient => file_upload_result: 'fail' and error message :" +JSON.stringify(file_upload_result_data['message']));
          throw new Error('Error in services:sendRequestPatient /patient/file/upload/ with '+JSON.stringify(file_upload_result));
          // request pk delete action 필요
        }
        else{
          console.log("Success services:sendRequestPatient/patient/file/upload/ with success and transfered file list : "+JSON.stringify(file_upload_result_data['message']));
          alert ('가구원 정보가 수정되었습니다.')
          return true
        }
        // 끝: 파일 업로드
        //---------------------------

      }
      // 끝: 파일 업로드
      //---------




      return true
      //const res_file_upload = await instance.post("/patient/file/upload/",formData,config)
    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }

  },

  // 사용자가 입력한 patient data 를 backend로 전달한다
  async sendRequestPatient(user_email, pb_obj, family, ecrf_obj, contract_sms, stakeholder_obj, _fileconstant){
    try{
      const config = parser.getConfigWithToken();

      // console.log('user_email:'+JSON.stringify(user_email)+',pb_obj:'+JSON.stringify(pb_obj)+',family:'+JSON.stringify(family)+',ecrf_obj:'+JSON.stringify(ecrf_obj)+'contract_sms:'+JSON.stringify(contract_sms)+ 'stakeholder_obj'+JSON.stringify(stakeholder_obj))

      // console.log ('sendRequestPatient pb_obj:'+JSON.stringify(pb_obj));
      // console.log ('sendRequestPatient family:'+JSON.stringify(family));

      // requestPatient 데이터 Json 만들기 시작
      // output : requestBasisPatientData
      //--------------------------------
      const requestBasisPatientData =  await this.constructRequestPatientData(user_email, pb_obj, family, ecrf_obj, contract_sms, stakeholder_obj, _fileconstant)
      // console.log ('after sendRequestPatient pb_obj:'+JSON.stringify(requestBasisPatientData));

      // 시작:  New patient registration
      //==================================
      //if (_update_req_id == undefined){

      // requestPatient 데이터 전송 시작
      //--------------------------------
      const res_patient= await instance.post('/patient/register/',requestBasisPatientData,config);
      // 새롭게 등록된 환자 정보

      const success = res_patient['data']['result']
      const res_data = res_patient['data']['data']


      if (success=='success'){
        // 시작: 파일 업로드를 위한 준비
        //-------------------------
        // res:{"data":{"result":"success","data":{"new_id":10}}
        // 1. check res_patient result

        const new_patient= res_data['patient_index']
        const new_family = res_data['family_indices']
        // 2. make formData
        //---------------------
        let formData = new FormData()
        //await this.addReqUploadfiles(formData,new_patient_id,pb_obj,'PB',_fileconstant)
        //await this.addReqUploadfiles(formData,-1,family,'FAM', _fileconstant)

        // add id to request objects
        // pb_obj 와 family에 backend에서 새로 생성된 id를 붙여준다
        let pb_reqfiles = pb_obj.reqfiles

        for (let i=0;i<pb_reqfiles.length;i++){
          pb_reqfiles[i].id = '-'
          // prefix 설정
          pb_reqfiles[i].prefix = _fileconstant.pb_prefix
          // 파일 타입 설정
          pb_reqfiles[i].filetype = 'REQ'
          // 패밀리 array index 설정
          //pb_reqfiles[i].array_idx = '-'
        }

        let pb_optfiles = pb_obj.optfiles
        for (let i=0;i<pb_optfiles.length;i++){
          // db id 설정
          pb_optfiles[i].id = '-'
          // prefix 설정
          pb_optfiles[i].prefix = _fileconstant.pb_prefix
          // 파일 타입 설정
          pb_optfiles[i].filetype = 'OPT'
          // 패밀리 array index 설정
          //pb_optfiles[i].array_idx = '-'
        }
        // async filearrayToFormData(_formData, _req_id, _files, _prefix, _type,  _fileconstant.prefix){
        await this.filearrayToFormData(formData,new_patient.id, pb_reqfiles)

        await this.filearrayToFormData(formData,new_patient.id, pb_optfiles)




        for (let i=0; i<new_family.length;i++){

          const new_family_dbid=new_family[i].id
          const fa_obj = family[i];
          let fa_reqfiles = fa_obj.reqfiles

          for (let j=0;j<fa_reqfiles.length;j++){
            fa_reqfiles[j].id = '-'

            fa_reqfiles[j].prefix = _fileconstant.fi_prefix
            // 파일 타입 설정
            fa_reqfiles[j].filetype = 'REQ'
            // 패밀리 array index 설정
            //fa_reqfiles[j].array_idx = i


          }
          let fa_optfiles = fa_obj.optfiles

          for (let j=0;j<fa_optfiles.length;j++){
            fa_optfiles[j].id = '-'

            fa_optfiles[j].prefix = _fileconstant.fi_prefix
            // 파일 타입 설정
            fa_optfiles[j].filetype = 'OPT'
            // 패밀리 array index 설정
            //fa_reqfiles[j].array_idx = i


          }

          await this.filearrayToFormData(formData,new_family_dbid, fa_reqfiles)
          await this.filearrayToFormData(formData,new_family_dbid, fa_optfiles)


        }

        // for (let i=0;i<family.length;i++){
        //   const family_obj = family[i]
        //   const family_reqfiles = family[i].reqfiles
        //   for (let j=0;j<family_reqfiles.length;j++){
        //     await this.filearrayToFormData(formData,family_obj.id, family_reqfiles)
        //   }
        //   const family_optfiles = family[i].optfiles
        //   for (let j=0;j<family_optfiles.length;j++){
        //     await this.filearrayToFormData(formData,family_obj.id, family_optfiles)
        //   }
        // }
        //await this.filearrayToFormData(formData,new_patient.id, pb_obj.optfiles, _fileconstant.fi_prefix,"OPT")
        //await this.filearrayToFormData(formData,new_patient.id, pb_obj.optfiles, _fileconstant.fi_prefix,"OPT")

        // 끝: 파일 업로드를 위한 준비
        //-------------------------

        // 시작: 파일 업로드
        //------------------------
        const res_file_upload = await instance.post("/patient/file/upload/",formData,config)
        const file_upload_result = res_file_upload['data']['result']
        const file_upload_result_data = res_file_upload['data']['data']

        if (file_upload_result == "fail"){
          // rollback 코드 필요
          console.log("Error inservices:registerPatient => file_upload_result: 'fail' and error message :" +JSON.stringify(file_upload_result_data['message']));
          throw new Error('Error in services:sendRequestPatient /patient/file/upload/ with '+JSON.stringify(file_upload_result));
          // request pk delete action 필요
        }
        else{
          console.log("Success services:sendRequestPatient/patient/file/upload/ with success and transfered file list : "+JSON.stringify(file_upload_result_data['message']));
          alert ('진단 의뢰한 환자 등록이 성공적으로 이루어졌습니다.')
          return true
        }
        // 끝: 파일 업로드
        //---------------------------
      }else if(success=='duplicate'){
        alert("이미 등록된 환자정보 입니다. 관리자에 문의 부탁드립니다.\n == 중복된 환아 정보 =="+
        "\n이름: " + res_data['name']+
        "\n성별: " + res_data['sex']+
        "\n생년월일: " + res_data['birthday']
        )
        return false
      }
      else if (success=='fail'){
        throw new Error('Error in services:sendRequestPatient /patient/register/ with '+JSON.stringify(res_data));
      }
      return true
      //}
      // 끝:  New patient registration
      //==================================

    } catch(err){
      alert ("서버와 통신 중에 문제가 생겼습니다. err:" + JSON.stringify(err));
      console.error(err);
      return err;
    }
  },
  // 오늘을 지정된 문자열로 변환한다.
  timestamp_to_str(_value){
    const value = _value
    var js_date = new Date(Date.now());
    if (value != ''){
      // return today
      js_date = new Date(value);
    }
    var year = js_date.getFullYear()
    var month = js_date.getMonth() +1
    var day = js_date.getDate()
    var hours = js_date.getHours()
    var minutes = js_date.getMinutes()
    var seconds = js_date.getSeconds()
    if (month < 10){
      month = '0'+month;
    }
    if (day < 10){
      day = '0'+day
    }
    if (hours <10){
      hours = '0'+hours;
    }
    if (minutes <10){
      minutes = '0'+minutes
    }
    if (seconds < 10){
      seconds = '0'+seconds
    }
    return year+'-'+month+'-'+day+ ' '+hours+':'+minutes+':'+seconds;
  },
  // 오늘을 tiemstamp 문자열 형태로 반환한다.
  tiemstamp_today(){

    var value = Math.round(Date.now() / 1000)

    return value+""
      //    }
      // return moment().format().valueOf()
  },
  async downloadFileObj(file_path){

    const config = parser.getConfigWithToken();
    config.responseType ='blob'
    const requestData = {file_path:file_path}
    // config.headers.params = {file_path:file_path}
    return await instance.post("/patient/file/download/",requestData,config)
  },
  


}







