import {createApp} from 'vue'

import store from '@/vuex/index.js'

import router from '@/router/base/index.js';

import App from './App.vue'

import Vuelidate from 'vuelidate';


import req_patient from '@/globals/req_patient.js' // [global]




import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// import moment from 'moment'

// Create Vue Instance
const app = createApp(App);

// app.filter('formatDate',function(value){
//     if (value){
//         return moment(String(value)).format('YYYYMMDD')
//     }
// })


//app.config.compilerOptions.isCustomElement = (tag) => { return tag !== 'font'; };

app.use(Vuelidate)
app.use(store).use(router).use(req_patient).mount('#app')

// new Vue({
//     el: "#app",
//     render: h => h(App),
//     store,
//     router,
//     Vuelidate
// })
