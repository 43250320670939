
<template>
  <Bar :data="chartData" :options="options" :width="width" :height="height" id="hospital-screen-bar-chart" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(ChartDataLabels, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'HospitalScreenChart',
  components: { Bar },
  props: {
    data: { type: Object, required: true },
    options: {
      type: Object,
      default: () => ({
        indexAxis: 'y',
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: context => {
                const datasetIndex = context.datasetIndex;
                const dataIndex = context.dataIndex;
                const originalValue = context.chart.data.originalData[dataIndex][datasetIndex];
                const percentageValue = context.raw.toFixed(2);
                return `${context.dataset.label}: ${originalValue} (${percentageValue}%)`;
              },
            },
          },
          datalabels: {
            display: true,
            formatter: (value, context) => {
              const datasetIndex = context.datasetIndex;
              const dataIndex = context.dataIndex;
              const originalValue = context.chart.data.originalData[dataIndex][datasetIndex];
              return `${originalValue}`;
            },
          },
        },
      }),
    },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 200 },
  },
  computed: {
    chartData() {
      const originalData = this.data.datasets[0].data.map((_, itemIndex) =>
        this.data.datasets.map(dataset => dataset.data[itemIndex])
      );

      const totalDataPerItem = this.data.datasets[0].data.map((_, itemIndex) =>
        this.data.datasets.reduce((acc, dataset) => acc + dataset.data[itemIndex], 0)
      );

      const normalizedDatasets = this.data.datasets.map(dataset => ({
        label: dataset.label,
        backgroundColor: dataset.backgroundColor,
        data: dataset.data.map((value, itemIndex) => {
          const total = totalDataPerItem[itemIndex];
          return total ? parseFloat(((value / total) * 100).toFixed(2)) : 0;
        }),
      }));

      return {
        labels: this.data.labels,
        datasets: normalizedDatasets,
        originalData: originalData,
      };
    },
  },
};
</script>

<style scoped>
/* 필요시 추가 스타일 작성 */
</style>


<!-- <template>
  <Bar :data="chartData" :options="options" :width="width" :height="height" id="hospital-screen-bar-chart" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(ChartDataLabels, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'HospitalScreenChart',
  components: { Bar },
  props: {
    data: { type: Object, required: true },
    options: {
      type: Object,
      default: () => ({
        indexAxis: 'y',
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: context => {
                const datasetIndex = context.datasetIndex;
                const dataIndex = context.dataIndex;
                const originalValue = context.chart.data.originalData[dataIndex][datasetIndex];
                console.log(`originalValue: ${originalValue}`);
                return `${context.dataset.label}: ${originalValue}`;
              },
            },
          },
          datalabels: {
            display: true,
            formatter: (value, context) => {
              const datasetIndex = context.datasetIndex;
              const dataIndex = context.dataIndex;
              const originalValue = context.chart.data.originalData[dataIndex][datasetIndex];
              return originalValue;
            },
          },
        },
      }),
    },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 200 },
  },
  computed: {
    chartData() {
      const originalData = this.data.datasets[0].data.map((_, itemIndex) =>
        this.data.datasets.map(dataset => dataset.data[itemIndex])
      );

      return {
        labels: this.data.labels,
        datasets: this.data.datasets.map(dataset => ({
          label: dataset.label,
          backgroundColor: dataset.backgroundColor,
          data: dataset.data,
        })),
        originalData: originalData,
      };
    },
  },
};
</script>

<style scoped>
/* 필요시 추가 스타일 작성 */
</style> -->
