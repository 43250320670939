<template>
    <div :key="this.isLoggedIn" v-if='this.isLoggedIn'>
        <div style="height:50px" />
        <!-- Start of DiagnosisScreenList.vue template -->
        <div class="container" style="align-items:left;width:1200px">

            <div class="loading text-center" v-if="this.is_loading()"><img src="../../assets/loader.gif" class=""></div>
            <!-- 시작: 검색 화면 -->
            <div class="container " :class="{ 'blur' : this.is_loading() }" style="align-items:left;width:1200px">
                <!-- 시작: 조회 옵션 설정 -->
                <div class="row"  >
                    <div class="col justify-content-left"  ref='up_position'>
                        <p style="font-weight: bold"><i class="fas fa-check"></i>&#x25A0; 진단 보고 리스트 &nbsp; &nbsp;
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <screen-search @fields_changed="make_list" ref="ref_search" />
            </div>
            <!--- 끝: 검색 화면-->
        </div>
        <!-- End of DiagnosisScreenList.vue template -->

        <!-- 시작: 조회 후 공백 설정 -->
        <div class="row" >
            <div class="col" style="height:30px;background-color: #ffffff">
            </div>
        </div>
        <!-- 끝: 조회 후 공백 설정 -->


        <!-- 시작: 환자 조회 리스트 -->
        <div class="row ">
            <div class="col  col-md-12" style="text-align:left">
                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_excel_download"
                    v-on:click.prevent="this.download_excel()"
                    style="float:right; margin-right: 10px;"> 환자 리스트 엑셀 다운로드 </button>
            </div>
        </div>
        <div class="row" >
            <div class="col" style="height:20px;background-color: #ffffff"></div>
        </div>
        <div class="row ">
            <div class="col col-md-12" style="text-align:left">
                <!-- 시작: backend로부터 받은 의뢰환자들 리스트 -->
                <table class="table table-hover" id="request_patient_tbl" style="width:100%">
                <thead>
                <tr class="tr-head-center">
                    
                    <th scope="col" width="2%">No</th>
                    <th scope="col" width="6%">환자명</th>
                    <th scope="col" width="6%">환자번호</th>
                    <th scope="col" width="2%">나이</th>
                    <th scope="col" width="2%">성별</th>
                    <th scope="col" width="6%">질환명</th>
                    <th scope="col" width="6%">의뢰기관</th>
                    <th scope="col" width="6%">담당의</th>
                    <th scope="col" width="6%">의뢰일</th>

                    <th scope="col" width="8%">결과보고일</th>


                    <th scope="col" width="8%">진행현황 및 검사결과</th>
                    <!--th scope="col" width="8%">결과전달여부</th-->

                </tr>
                </thead>
                <tbody>
                <template v-for="(row,idx) in this.req_pb_obj_list" v-bind:key="idx">
                 
                <tr class="tr-row-center" >

                    
                    <!-- 시작: 환자 기본 정보들 -->
     
                    
                    <td style='vertical-align:middle' scope="row">{{this.total_item_cnt-(((this.current_page-1)*this.page_size)+idx)}}</td>

                    <td style='vertical-align:middle' v-if="row['pb_obj']['text_pb_reqstatus']=='의뢰전' || row['pb_obj']['text_pb_reqstatus']=='반려'">
                        <font color="#0d6efd"> {{row['pb_obj']['text_pb_name']}}</font>
                    </td>
                    <td style='vertical-align:middle' v-else>
                        <!-- {{row['pb_obj']['text_pb_name']}} -->
                        <font color="#008000"> {{row['pb_obj']['text_pb_name']}}</font>
                    </td>
                    <td style='vertical-align:middle'>{{row['pb_obj']['text_pb_number']}}</td>
                    <td style='vertical-align:middle'>{{row['pb_obj']['age']}}</td>
                    <td style='vertical-align:middle'>{{row['pb_obj']['sel_pb_sex']}}</td>
                    <td style="text-align:left;vertical-align:middle">{{row['pb_obj']['pb_diseasename_text']}}</td>
                    <td style='vertical-align:middle'>{{row['pb_obj']['hospital']}}</td>
                    <td style='vertical-align:middle'>{{row['processed_contract_sms']['text_sms_request_doc_name']}}</td>
                    <td style='vertical-align:middle' >{{row['pb_obj']['text_pb_reqreferal_date']}}</td>





                    <!-- td style='vertical-align:middle' >{{this.list_date(row['pb_obj']['text_pb_reqscreened'])}}</td-->

                    <!--td style='vertical-align:middle' ><div style="float: left;" v-html="this.html_req_screened_date[idx]"></div></td-->
                    <td style='vertical-align:middle' v-if="row['pb_obj']['text_pb_reqstatus']!='검사완료'">
                        <font color="#ff0000">{{this.convert_patient_status(row['pb_obj']['text_pb_reqstatus'])}}</font>
                    </td>
                    <td style='vertical-align:middle' v-else>
                        <template v-for="(report_content, jdx) in row['pb_obj']['html_report_contents']" v-bind:key="jdx">
                            
                            <div style="float: left;" v-html="report_content['report_date']"></div><br/>
                        </template>
                    </td>
                    



                    <td style='vertical-align:middle' v-if="row['pb_obj']['text_pb_reqstatus']=='의뢰전'"><font color="#ff0000">{{this.convert_patient_status(row['pb_obj']['text_pb_reqstatus'])}}</font></td>
                    <td style='vertical-align:middle' v-else-if="row['pb_obj']['text_pb_reqstatus']=='반려'"><font color="#ff7f00">{{this.convert_patient_status(row['pb_obj']['text_pb_reqstatus'])}}</font></td>
                    <td style='vertical-align:middle' v-else-if="row['pb_obj']['text_pb_reqstatus']=='검사완료'">
                        <template v-for="(report_content, jdx) in row['pb_obj']['html_report_contents']" v-bind:key="jdx">
                            <!--div style="float: center;" v-if="report_content['report_type'] ==='WOFILE'" >
                                <div style="float: center;" v-if ="row['pb_obj']['html_report_contents'].length -1 == jdx">
                                    <font color='#0000ff'><b>[{{report_content['display_msg']}}](최종)</b></font>
                                </div>
                                <div style="float: center;" v-else>
                                    <font color='#0000ff'>[{{report_content['display_msg']}}]</font>
                                </div>
                            </div-->
                            <div style="float: center;" v-if="report_content['report_type']==='FILE' || report_content['report_type']=='__FILE__'" 
                                @click.stop=""  @click="this.file_download(report_content['filepath'], report_content['filename'])">
                                <div style="float: center;" v-if ="row['pb_obj']['html_report_contents'].length -1 == jdx">
                                    <font color='#0000ff'><b>[{{report_content['display_msg']}}](최종)</b></font>
                                </div>
                                <div style="float: center;" v-else>
                                    <font color='#0000ff'>[{{report_content['display_msg']}}]</font>
                                </div>
                            </div>
                            <div style="float: center;" v-else-if="report_content['report_type'].includes('EMR')"> 
                                
                                <div style="float: center;" v-if ="row['pb_obj']['html_report_contents'].length -1 == jdx">
                                <font color='#ff7f00'><b>{{report_content['display_msg']}}(최종)</b></font> 
                                </div>
                                <div style="float: center;" v-else>
                                <font color='#ff7f00'>{{report_content['display_msg']}}</font> 
                                </div>
                            </div>
                            <div style="float: center;" v-else-if="report_content['report_type'].includes('URL')"> 
                                <div style="float: center;" v-if ="row['pb_obj']['html_report_contents'].length -1 == jdx">
                                    <font color='#ff7f00'><b><span v-html="report_content['display_msg']"></span> (최종)</b></font>
                                </div>
                                <div style="float: center;" v-else>
                                    <font color='#ff7f00'><span v-html="report_content['display_msg']"></span></font>
                                </div>
                                
                            </div>
                        </template>
                    </td>
                    <td style='vertical-align:middle' v-else>{{this.convert_patient_status(row['pb_obj']['text_pb_reqstatus'])}}</td>

                   
                    <!-- 끝: 환자 기본 정보들  -->
                </tr>
                </template>
                </tbody>
                </table>
                <!-- 끝: backend로부터 받은 의뢰환자들 리스트 -->
            </div>
            <div class="col" >
                <!-- Showing 1 to 5 of 282 rows  rows per page -->
                <label class="col-1 label-search-center" style="width:200px;">Showing {{this.current_page}} to {{this.page_size}} of {{this.total_item_cnt}} rows </label>
                <select id="sel_search_page_size" name="sel_search_page_size" v-model="this.page_size" class="form-select-sm select-search" @change="this.make_list()" style="width:60px" >
                    <option value="2">2</option>
                    <option value="5">5</option>
                    <option value="10" selected>10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                </select>
                <label for="sel_search_page_size" class="col-1 label-search-center" style="width:95px;">rows per page </label>
            </div>
            <div class="col">
                <nav aria-label="..." style="float:right;">
                    <ul class="pagination  pagination-sm">
                        <a class="page-link" v-on:click="this.move_page(1)">First</a>
                        <li class="page-item" v-if="this.current_page>=3"><span class="page-link">...</span></li>
                        <li class="page-item" :class="{ 'active' : this.current_page==page_count }" v-for="page_count in (Math.ceil(this.total_item_cnt/this.page_size))" v-bind:key="page_count">
                            <a v-show="page_count>= this.current_page-5 && page_count<= this.current_page+5" v-if="(this.current_page==page_count)" class="page-link">{{page_count}}</a>
                            <a v-show="page_count>= this.current_page-5 && page_count<= this.current_page+5" v-else-if="(page_count>=this.current_page-9) && (page_count<=this.current_page+9)" class="page-link" v-on:click="this.move_page(page_count)">{{page_count}}</a>
                        </li>
                        <li class="page-item" v-if="(this.current_page+1)*this.page_size<this.total_item_cnt&&Math.ceil(this.total_item_cnt/this.page_size)!=this.current_page"><span class="page-link">...</span></li>
                        <a class="page-link" v-on:click="this.move_page(Math.ceil(this.total_item_cnt/this.page_size))">Last</a>
                    </ul>
                </nav>
            </div>
            <div class="row" >
                <div class="col" style="height:40px;background-color: #ffffff"></div>
            </div>
        </div>
        <!-- 끝: 환자 조회 리스트 -->


    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { mapGetters } from 'vuex'

import { ref , reactive} from 'vue';

import api from '@/services/base/index.js'

const userStore = 'userStore'



import Search from '@/views/base/Search.vue'
//import FileUploader from '@/views/base/FileUploader.vue'
//import { json } from 'd3';

import base_parser from '@/helper/base_parser.js'
import {isProxy, toRaw} from 'vue';

export default {
    components: {
        'screen-search':Search,
  //      'fileuploader':FileUploader
    },
    name: 'DiagnosisScreenManageList',
    data() {
        // const is_tabsearchmenu_active = true;
        // const is_tabreqregister_active = false;

        const router = useRouter ()

        let loading = ref(false);

        const req_pb_obj_list = reactive([])

        
        // 끝: 데이터 및 상태 현황 관리 변수들
        //--------------------------
        let checked_req_patient_list = reactive([]);


        

        //--------------------------

        // paging 관련
        //=========================================================
        let current_page = 1; // 현재 페이지
        let page_size = 10; // 한번에 표시하는 페이지 단위
        let total_item_cnt = 0; // 총 검색 건 수


        let search_data=reactive({
                search_progress:'',
                search_requesting_institution:'',
                search_disease_name:'',
                search_request_doc_name:'',
                search_request_date_start:'',
                search_request_date_end:'',
                search_request_patient_name:'',
                search_screened_date_start:'',  // 결과 보고일 시작
                search_screened_date_end:''  // 결과 보고일 끝
            })
        //=========================================================



        // return {router,loading, current_page, page_size, total_item_cnt, req_pb_obj_list, checked_req_patient_list, html_req_screened_date, screened_details, search_data}
        return {router,loading, current_page, page_size, total_item_cnt, req_pb_obj_list, checked_req_patient_list, search_data}

    },
    computed : {
        ...mapGetters(userStore,['isLogin']),
        isLoggedIn : function(){
            // alert('isLogin:'+this.isLogin)
            return this.isLogin
        },

    },
    methods: {
        is_loading(){
            return this.loading
        },
        set_loading(_loading){
            this.loading = _loading
        },
        move_page(target_page){
            this.current_page = target_page;
            this.make_list();
        },
        patient_checked(_patient_id){
            //alert ("checked_req_pateint_list:"+JSON.stringify(this.checked_req_patient_list))
            return this.checked_req_patient_list.includes(_patient_id)
        },
        async report_uploaded(){
            this.forceRender()
        },
        // /Check report is reported or not 
        is_reported(_result_url, _report_type){ 
            return _result_url.includes(_report_type) 
        },
        
        convert_patient_status(_status){
            return base_parser.convert_patient_status(_status)
        },
        
        download_excel(){
            

            const userEmail = this.store.getters['userStore/getUserEmail']

            this.set_loading(true)

            // 검색 데이터 구성
            this.construct_search_data()


            // excel data 수집을 위해, this.current_page, this.page_size, this.total_item_cnt 를 0으로 세팅
            api.getRequestedPatients(userEmail, 0, 0, "SCREEN", this.get_search_data())
            .then(response =>{

                /* 시작: 엑셀 파일 생성을 위한 자료 json 형태로 구축 */
                const excel_pb_obj_list = Object.freeze(response.data)
                const headers = ['No', '환자명', '환자번호','검체번호', '나이','성별', '생일', 'TEXT_질환명', 'HPO_질환명','OMIM_질환명',
                    '담당의','의뢰기관', '의뢰의', '의뢰일','진행상태','보고완료일']
                let excel_data=[]
                // this.req_pb_obj_list = Object.freeze(requested_patients)
                for (let i = 0 ; i < excel_pb_obj_list.length;i++){
                    // this.patient_number_list.push(excel_pb_obj_list[i].pb_obj.text_pb_number)



                    const patient_info = excel_pb_obj_list[i]
                    const pb_obj = patient_info['pb_obj']
                    

                    // 담당의 정보 추출
                    const doctor_obj = patient_info['processed_contract_sms']
                    const doctor_name = doctor_obj['text_sms_request_doc_name']

                    
                    
                    const excelLogs = {}
                    for (let j=0;j<headers.length;j++){
                        excelLogs[headers[j]]=''
                    }
                    excelLogs['No'] = i+1
                    // excelLogs['DBID'] = pb_obj.id
                    excelLogs['환자명'] = pb_obj.text_pb_name
                    excelLogs['환자번호'] = pb_obj.text_pb_number
                    excelLogs['검체번호'] = pb_obj.text_sample_number
                    excelLogs['나이'] = pb_obj.age
                    excelLogs['성별'] = pb_obj.sel_pb_sex
                    excelLogs['생일'] = pb_obj.text_pb_birthday
                    excelLogs['TEXT_질환명'] = pb_obj.pb_diseasename_text
                    excelLogs['HPO_질환명'] = pb_obj.pb_diseasename_hpo
                    excelLogs['OMIM_질환명'] = pb_obj.pb_diseasename_omim
                    excelLogs['담당의'] = doctor_name
                    excelLogs['의뢰기관'] = pb_obj.hospital
                    excelLogs['의뢰의'] = pb_obj.writername
                    excelLogs['의뢰일']= pb_obj.text_pb_reqreferal_date
                    excelLogs['진행상태']= base_parser.convert_patient_status(pb_obj.text_pb_reqstatus)
                    excelLogs['보고완료일'] = pb_obj.text_pb_reqscreened
                    //excelLogs['보고내용'] = pb_obj.result_url

                    excel_data.push(excelLogs)
                }
                /* 끝: 엑셀 파일 생성을 위한 자료 json 형태로 구축 */

                /* 시작: 엑셀 파일 생성 */
                const XLSX = require('xlsx');
                // 엑셀 워크시트로 json 내보내기, 배열만 가능
                const dataWS = XLSX.utils.json_to_sheet(excel_data)
                // 엑셀의 workbook (엑셀 파일에 지정된 이름)을 만든다.
                const wb = XLSX.utils.book_new()
                //XLSX.utils.book_append_sheet(wb,dataWS,[['Created:'+new Date().toISOString()]], {origin:-1})
                const created_date = new Date().toDateString()
                XLSX.utils.book_append_sheet(wb,dataWS,[['진단검사리스트 '+created_date]])
                XLSX.writeFile(wb, "Screened_patient_list.xlsx");
                /* 끝: 엑셀 파일 생성 */
                this.set_loading(false);

            }).catch(err =>{
                console.log(err)
            })
        },
        // report를 롤백(회수)한다
        async report_rollback(_fields){
            //alert ( 'pid:'+pid+'fid:'+fid);
            const p_id = _fields['p_id']
            const f_id = _fields['file_id']
            const userEmail = this.store.getters['userStore/getUserEmail']
            this.set_loading(true);
            api.rollBackReport(userEmail, p_id,"접수완료", f_id)
            .then(response =>{
                const response_data = Object.freeze(response)

                if (response_data['result'] =="success"){
                    //alert ('success')
                    // checked interface process
                    this.forceRender()
                }
                else{
                     alert ('some error in api.rollBackReport()');
                    
                }
                this.set_loading(false);
                return response_data

            }).catch(err =>{
                console.log(err)
            })

        },
        // report를 삭제한다.
        async report_removed(_fields){
            
            const f_id = _fields['file_id']

            

            this.set_loading(true);
            // 시작: 검사완료 로 변경 (from backend) 
            //  async deliverFileObj(user_email, id, reqstatus, _fidx){
            //--------------------------------------------------------
            api.deleteFileObj("",f_id)
            .then(response =>{
                const response_data = Object.freeze(response)
                //alert ('deleteFileObj :'+JSON.stringify(response_data))

                if (response_data['result'] =="success"){
                    //alert ('success')
                    // checked interface process
                    this.forceRender()
                }
                else{
                     alert ('some error in api.removeReport()');
                    
                }
                this.set_loading(false);
                return response_data

            }).catch(err =>{
                console.log(err)
            })

        },
        
        async forceRender() {
            // Remove MyComponent from the DOM
            //this.renderComponent = false;

            // Then, wait for the change to get flushed to the DOM
            //await this.$nextTick();

            // Add MyComponent back in
            //this.renderComponent = true;

            localStorage.setItem('checked_req_patient_list',JSON.stringify(this.checked_req_patient_list))
            this.$router.go();

        },
        // 사용자가 입력한 search data set 을 저장한다.
        async construct_search_data(){

            
            let fields = this.$refs['ref_search'].get_fields()
            if (isProxy(fields)){
                fields = toRaw(fields)
            }



            this.search_data['search_progress'] = fields.search_progress
            this.search_data['search_requesting_institution'] = fields.search_requesting_institution
            this.search_data['search_disease_name'] = fields.search_disease_name

            this.search_data['search_request_doc_name'] = fields.search_request_doc_name
            this.search_data['search_request_date_start'] =fields.search_request_date_start

            this.search_data['search_request_date_end'] = fields.search_request_date_end
            this.search_data['search_request_patient_name'] = fields.search_request_patient_name

            this.search_data['search_screened_date_start'] = fields.search_screened_date_start
            this.search_data['search_screened_date_end'] = fields.search_screened_date_end

        },
        get_search_data(){
            return this.search_data
        },
        async make_list(){

            

            const userEmail = this.store.getters['userStore/getUserEmail']

            this.set_loading(true)

            // 검색 데이터 구성
            this.construct_search_data()

            
            // 시작: 등록된 환자 리스트 만들기 (from backend)
            //--------------------------------------------------------
            api.getRequestedPatients(userEmail, this.current_page, this.page_size, "SCREEN", this.get_search_data())
            .then(response =>{


                //search_screened_date_start 과 search_screened_date_end 에 해당하는 환자들을 리스트업한다.
                this.patient_number_list = [];
                this.req_pb_obj_list = [];
                


                /* 끝: 결과보고일 순으로 정렬 그 외 단계에서 의뢰일 기준으로 정렬 */
                this.req_pb_obj_list = Object.freeze(response.data)
   


                
                // this.req_pb_obj_list = Object.freeze(response.data)
                // 시작: 결과 보고에 대한 데이터 채워넣기
                //--------------------------------------------------------------------
                // this.patient_number_list = [];
                // this.screened_details = [];

                
                
                for (let i = 0; i < this.req_pb_obj_list.length; i++) {

                    const pb_obj_data = this.req_pb_obj_list[i].pb_obj;
                    this.patient_number_list.push(pb_obj_data.text_pb_number)
                    
                    this.req_pb_obj_list[i]['pb_obj']['html_report_contents'] =[]

                    
                    if (pb_obj_data.text_pb_reqstatus == "검사완료"){
                        
                        //this.screened_details.push([])
                        const report_contents = pb_obj_data.report_contents[0];
                        
                        for (let j=(report_contents.length-1);j>=0;j--){
                            let report_content = report_contents[j]

                            
                            
                            
                            // this.screened_details[i].push([])

                            let rep_report = {}
                            

                            
                            // id, patient_id, patientfiles_category, patientfiles_file_path, patientfiles_filename, patientfiles_id, patientfiles_upload_date, report_date, report_kind, report_result, report_type, report_value, revoke, seq_type
                            // id :1705
                            // patientfiles_file_path: 
                            // patientfiles_filename
                            // patientfiles_id
                            // report_date: 2024-12-20T15:52:45
                            // report_type: "FILE"
                            rep_report['report_date'] = report_content['report_date'].replace('T',' ')

                            rep_report['report_type'] = report_content['report_type']

                            if (report_content['report_type']==='WOFILE' || report_content['report_type'] == '__WOFILE__'){
                                rep_report['display_msg'] = "이전파일보고참고"

                            }
                            else if (report_content['report_type']=='FILE' ||  report_content['report_type'] == "__FILE__"){
                                rep_report['display_msg'] = "보고서 파일";
                                // rep_report['report_value'] = report_content['patient_filename'] +"|"+ report_content['patientfiles_file_path']
                                rep_report['filename'] = report_content['patientfiles_filename']
                                rep_report['filepath'] = report_content['patientfiles_file_path']
                            }
                            else if (report_content['report_type'].includes('EMR')){
                                rep_report['display_msg'] = "EMR 보고됨";
                                rep_report['report_value'] = 'EMR'
                            }
                            else if (report_content['report_type'].includes('URL')){
                                rep_report['display_msg'] = "<a href='"+report_content['report_value'] + " target='_blnak'>보고서 링크</a>"
                            }

                            if (report_content['report_type'] != 'WOFILE'  &&  report_content['report_type'] != '__WOFILE__'){
                                this.req_pb_obj_list[i]['pb_obj']['html_report_contents'].push(rep_report)

                            }

                        }
                        //최종
                        
                    }
                    
                    // make html_req_screened_date array and html_req_screened_details
                    //------------------------------
                    // this.screened_details.push([])
                    // if (pb_obj_data.text_pb_reqscreened != undefined){
                    //     if (pb_obj_data.text_pb_reqstatus == "검사완료"){
                    //         this.html_req_screened_date[i] = this.listup_date(pb_obj_data.text_pb_reqscreened);
                    //     }
                    //     else{
                    //         this.html_req_screened_date[i] = "-"
                    //     }

                    //     // construct req_result_url information to construct screened_details
                    //     //-----------------------------------------------------------------------
                        
                    //     if (pb_obj_data.text_pb_reqstatus == "검사완료"){
                    //         const resulturl = pb_obj_data.result_url;

                    //         const url_array = resulturl.split("|");    
                           
                    //         for (let j =0;j<url_array.length;j++){
                    //             this.screened_details[i].push({})
                    //             // select report_type
                    //             //-----------------------------------------------------------------------
                    //             var report_type = "__EMR__";
                    //             var display_msg = "EMR 보고됨";
                    //             // select detail info
                                
                    //             const url_value = url_array[j]
                    //             if (url_value.includes('__FILE__')){
                    //                 report_type= '__FILE__';
                    //                 display_msg = "보고서 파일"
                    //                 // Processing file id
                    //                 const file_id_arr = url_value.split('__FILE__');
                    //                 const file_id = file_id_arr[1]
                    //                 const diafiles = pb_obj_data.diafiles;
                    //                 for (let f=0;f<diafiles.length;f++){
                    //                     const fileinfo = diafiles[f]
                    //                     if (file_id == fileinfo['id']){
                    //                         this.screened_details[i][j]['filename'] = fileinfo['filename']
                    //                         this.screened_details[i][j]['filepath'] = fileinfo['file_path']
                    //                         break;
                    //                     }
                                        
                    //                 }
                                    

                    //             }
                    //             else if (url_value.includes('__URL__')){
                    //                 report_type= '__URL__';
                    //                 display_msg = "보고서 링크"
                    //                 this.screened_details[i][j]['url'] ="<a href='"+url_value.replace('__URL__','')+"' target='_blank' >" + display_msg +"</a>"
                    //             }

                    //             this.screened_details[i][j]['report_type'] = report_type;
                    //             this.screened_details[i][j]['display_msg'] = display_msg;
                    //             //-----------------------------------------------------------------------

                    //         }
            
                            
                    //     }
                    //     //------------------------------------------------------------------------               
                    // }
                    // else{
                    //     this.html_req_screened_date[i] = "";
                    // }
                    
                    //------------------------------
                }
                //--------------------------------------------------------------------
                // 끝: 결과 보고에 대한 데이터 채워넣기
                
                this.current_page = response['page_info']['current_page'];
                this.total_item_cnt = response['page_info']['total_item_cnt'];

                this.set_loading(false);

            }).catch(err =>{
                console.log(err)
            })

        },
        async file_download (file_path, file_name) {
            api.downloadFileObj(file_path).then(response=>{
                let blob = new Blob([response.data], { type: response.headers['content-type'] })
                file_name = decodeURI(file_name) // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
                if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                    window.navigator.msSaveOrOpenBlob(blob, file_name)
                } else { // not IE
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.target = '_self'
                    if (file_name) link.download = file_name
                    link.click()
                }
            })
        },

        // getReqPatients : backend로부터 환자 리스트 반환 R
        // getReqPatient : backend로 부토 특정 환자 반환 R
        // addReqPatient : 새로운 환자 등록 C
        // updateReqPatient : 환자 정보 수정 U
        // deleteReqPatient : 환자 정보 삭제 D

    },
    created(){
    },
    mounted:function(){
        //alert ('DiagnosisScreenManageList::mounted()')

        //checkbox setting
        const checked_req_patient_list = localStorage.getItem('checked_req_patient_list')
        //alert (JSON.stringify(checked_req_patient_list))

        base_parser.clone(JSON.parse(checked_req_patient_list), this.checked_req_patient_list)
        localStorage.removeItem('checked_req_patient_list')
        
        this.make_list()


    },
    setup(){
        const store = useStore();
        // var temp_basis_info = JSON.parse(localStorage.getItem('pb_info'));
        // alert ('loaded:'+JSON.stringify(temp_basis_info));
        // this.pb_obj = temp_basis_info;

        return {store}
    }
}
</script>

<style scoped>
@import '@/assets/screen.css'

/* $(".nav-tabs li a").click(function(){
  $(".nav-tabs li a").removeClass("active");
  $(this).addClass("active");
}); */
</style>
