<template>

  <div class="loading text-center" v-if="!this.is_loaded()"><img src="../../assets/loader.gif" class=""></div>
  <div class="d-flex flex-row"  v-else >
  <div class="d-flex flex-row"></div>

    <!--div class="p-2" style='width:260px'>
      <Bar :data="chartData" :options="options" :width="250" :height="410" />
    </div>
    <div class="p-2" style='width:40px'>
      <div class="d-flex flex-column">
        <div height="15px" >
        </div>

        <div v-for="(val,id) in this.statTextDisplay" :key="id" style='width:40px;height:21px; position: relative;top:5px;margin-top:1px;'>
          <span style="font-size:11px;margin-top:0px;margin-bottom:0px;">{{val}}</span>
        </div>
      </div>
    </div-->
  </div>
</template>

<script>
import base_parser from '@/helper/base_parser.js'


import api from '@/services/base/index.js'



// import { Bar } from 'vue-chartjs'
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//import { reactive } from 'vue'

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  name: 'StatViewManagerData',
//  components: { Bar },
  //extends : Bar,
  data() {
    return {
      loaded:false,
      statData:{},
      statDash:{},
      statScreen:{},
      statReqType:{},
      statSex:{},
      statAge:{},
      statTAT:{},
      statHospitalReqStatus:{},
      statTextDisplay:[],
      chartData:{
        labels: [],
        datasets: [{
          label: '',
          backgroundColor: [], // bar color
          data: []
        }]
      },
      options: {
        verticalAlign: "top",
        maintainAspectRatio :false,
        // tooltips: {enabled: false},
        // hover: {mode: null},
        responsive: false,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true
          },
          tooltip: {
            boxWidth: 12
          }
        },
      }
    }
  },
  async created(){
    //alert ('RegionBarChart::created() will be callled')
    //alert ('RegionBarChart::created() was called')
  },
  // mounted function
  async mounted() {
    // alert ('RegionBarChart::mounted() will be callled')

    // set loaded to false
    this.set_loaded(false)
    // get stat data from backend to this.statData
    try{

      //fetch statdata from backend (response.data.data)
      const response = await api.getStatManagerData()
      if (response.data.result == 'success'){
        // copy response.data.data to this.statData
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["MAP"])), this.statData);
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["DASH"])), this.statDash);
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["SCREEN"])), this.statScreen);
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["REQTYPE"])), this.statReqType);
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["SEX"])), this.statSex);
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["AGE"])), this.statAge);
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["TAT"])), this.statTAT);

        //console.log("StatViewManagerData")
        base_parser.clone(JSON.parse(JSON.stringify(response.data.data["HOSPITALREQSTATUS"])), this.statHospitalReqStatus);
        

        this.set_loaded(true)
        //alert ('RegionBarChart::created() was callled emit stat_loaded will be called')
        // notice 'stat_loaded' event to StatView parent component
        this.$emit('stat_loaded',"true")
        //alert ('RegionBarChart::created() emit stat_loaded was called')

      }
      else{
        alert('Error in commmunicate with backend')
        throw new Error('response.data.result is not success');
      }

    } catch(e){
      console.error(e)

    }

    // make this.chartData using this.statData
    this.summarize();


    //this.renderChart(this.chartData, this.options);

  },
  methods:{
    getStatHospitalReqStatus(){
      return this.statHospitalReqStatus;
    },
    getStatScreen(){
      return this.statScreen;
    },
    getStatReqType(){
      return this.statReqType;
    },
    getStatDash(){
      return this.statDash;
    },
    getStatSex(){
      return this.statSex;
    },
    getStatAge(){
      return this.statAge;
    },
    getStatTAT(){
      return this.statTAT;
    },
    getStatData(){
      return this.statData;
    },
    
    set_loaded(_loaded){
      this.loaded = _loaded
    },
    is_loaded(){
      //return  this.CRUDENV.loading;
      return this.loaded;
    },

    // make this.chartData using this.statData
    summarize() {

      //this.chartData
      this.chartData.datasets.backgroundColor = this.getTotalColorHexArray()

      this.chartData.datasets=[]

      var datasets = {label:[],backgroundColor:[],data:[]}
      Object.keys(this.statData).forEach(key =>{
        if (key !="TOTAL" && key !="NA"){
          const properties = this.statData[key]

          const ratio = parseInt((100* properties['TOTAL'])/this.statData['TOTAL']['TOTAL'])

          this.statTextDisplay.push(ratio +" %")

          this.chartData.labels.push(properties['AREA'])

          datasets['data'].push(properties['TOTAL']);
          datasets['backgroundColor'].push(this.provinceColor(key));
        }

      });

      this.chartData.datasets.push(datasets)


      //console.log("RegionBarChart stat:"+JSON.stringify(this.chartData))


    },
    // get first 6 color array
    getTotalColorFirstHalfHexArray(){
      return ["#F2F6FF", "#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF" ,
              "#80BFFF","#66B3FF"]
    },
    // get last 6 color array
    getTotalColorLastHalfHexArray(){
      return ["#4DA6FF","#3399FF","#1A8CFF" ,"#0080FF",
        "#0073E6","#0066CC"]
    },
    // get total 12 colors
    getTotalColorHexArray(){
      var merged = [];
      merged = this.getTotalColorFirstHalfHexArray().concat(this.getTotalColorLastHalfHexArray());
      return merged
    },

    // return  province color designated by code
    provinceColor(code) {
      let color = null;
      const provinceCode = "" + code;

      const fillColors = this.getTotalColorHexArray()
      //["#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF" ,"#80BFFF",
                          //"#66B3FF","#4DA6FF","#3399FF","#1A8CFF","#0080FF",
                         // "#0073E6","#0066CC"]

      //console.log("statData:"+JSON.stringify(this.statData))
      if(provinceCode in this.statData) {
        //const localSeat = this.statFinal[localSeatCode];
        var statInfo = this.statData[provinceCode]
        var totalNumber = statInfo['TOTAL']

        var ratio = totalNumber/1200

        //convert float to integer between 0 to 12
        if (ratio >=1.0){
          ratio = 0.999
        }
        var colorIdx = parseInt(ratio * 12.0)
        return fillColors[colorIdx]
      }
      else{
        color = "#FFFFFF"
      }

      return color;
    },
  }

}

</script>
<style scoped>

</style>
